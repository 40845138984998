import React from "react";
import theme from "theme";
import { Theme, Text, Icon, Box, Section, Image, Em } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, StackItem, Stack } from "@quarkly/components";
import * as Components from "components";
import { MdArrowDownward } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				 Pure Bliss Cleaning Co
			</title>
			<meta name={"description"} content={"Our team is passionate about providing exceptional cleaning services that will enhance your living space. With Pure Bliss you will enter a world of cleanliness where every detail matters."} />
			<meta property={"og:title"} content={" Pure Bliss Cleaning Co"} />
			<meta property={"og:description"} content={"Our team is passionate about providing exceptional cleaning services that will enhance your living space. With Pure Bliss you will enter a world of cleanliness where every detail matters."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/65cc7cb3fdcae00021de98ce/images/4-4%20%281%29.jpg?v=2024-02-14T09:52:47.704Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/65cc7cb3fdcae00021de98ce/images/4-4%20%281%29.jpg?v=2024-02-14T09:52:47.704Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/65cc7cb3fdcae00021de98ce/images/4-4%20%281%29.jpg?v=2024-02-14T09:52:47.704Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/65cc7cb3fdcae00021de98ce/images/4-4%20%281%29.jpg?v=2024-02-14T09:52:47.704Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/65cc7cb3fdcae00021de98ce/images/4-4%20%281%29.jpg?v=2024-02-14T09:52:47.704Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/65cc7cb3fdcae00021de98ce/images/4-4%20%281%29.jpg?v=2024-02-14T09:52:47.704Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/65cc7cb3fdcae00021de98ce/images/4-4%20%281%29.jpg?v=2024-02-14T09:52:47.704Z"} />
		</Helmet>
		<Components.Header>
			<Override slot="quarklycommunityKitMobileSidePanelOverride2" />
			<Override slot="quarklycommunityKitMobileSidePanel" />
			<Override slot="text" />
			<Override slot="image" />
			<Override slot="link" />
			<Override slot="socialMedia" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="text1" />
			<Override slot="quarklycommunityKitMenuOverride4" />
		</Components.Header>
		<Section
			background="linear-gradient(0deg,rgba(4, 8, 12, 0.6) 0%,rgba(4, 8, 12, 0.6) 100%),--color-darkL2 url(https://uploads.quarkly.io/65cc7cb3fdcae00021de98ce/images/4-4%20%281%29.jpg?v=2024-02-14T09:52:47.704Z) center/cover"
			padding="64px 0"
			sm-padding="40px 0"
			color="--light"
			font="--base"
		>
			<Stack>
				<StackItem width="75%" lg-width="100%">
					<Override slot="StackItemContent" flex-direction="column" />
					<Text color="--lightD2" letter-spacing="1px" text-transform="uppercase" margin="0">
						Feel the Joy of a Perfect Home
					</Text>
					<Text as="h1" font="--headline1" md-font="--headline2" margin="10px 0">
						Pure Bliss, Where Cleanliness Meets Serenity
					</Text>
				</StackItem>
			</Stack>
			<Box text-align="center" margin="96px 0 0 0">
				<Text margin="8px 0" text-transform="uppercase">
					More
				</Text>
				<Icon category="md" margin="0 auto" icon={MdArrowDownward} />
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
				padding="0px 0px 0px 0px"
				lg-padding="0px 30px 0px 0px"
				md-padding="0px 0px 0px 0px"
				md-margin="0px 0px 30px 0px"
			>
				<Image
					max-width="500px"
					src="https://uploads.quarkly.io/65cc7cb3fdcae00021de98ce/images/1-4.jpg?v=2024-02-14T09:52:47.668Z"
					md-margin="0px 0px 20px 0px"
					height="100%"
					width="100%"
					srcSet="https://smartuploads.quarkly.io/65cc7cb3fdcae00021de98ce/images/1-4.jpg?v=2024-02-14T09%3A52%3A47.668Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65cc7cb3fdcae00021de98ce/images/1-4.jpg?v=2024-02-14T09%3A52%3A47.668Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65cc7cb3fdcae00021de98ce/images/1-4.jpg?v=2024-02-14T09%3A52%3A47.668Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65cc7cb3fdcae00021de98ce/images/1-4.jpg?v=2024-02-14T09%3A52%3A47.668Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65cc7cb3fdcae00021de98ce/images/1-4.jpg?v=2024-02-14T09%3A52%3A47.668Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65cc7cb3fdcae00021de98ce/images/1-4.jpg?v=2024-02-14T09%3A52%3A47.668Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65cc7cb3fdcae00021de98ce/images/1-4.jpg?v=2024-02-14T09%3A52%3A47.668Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
			>
				<Text margin="0px 0px 40px 0px" color="--dark" font="--headline2" md-margin="0px 0px 30px 0px">
					Welcome to Pure Bliss Cleaning Co
				</Text>
				<Text margin="0px 0px 40px 0px" color="--darkL2" font="--base">
					At Pure Bliss, we believe in the transformative power of a clean home. It’s not just about removing dust and dirt—it’s about creating a space where you can relax and recharge. Our team is passionate about delivering exceptional cleaning services that enhance your living space.
				</Text>
				<Text margin="0px 0px 0px 0px" color="--darkL2" font="--lead">
					<Em>
						With Pure Bliss, you enter a world of cleanliness where every detail matters.
					</Em>
				</Text>
			</Box>
		</Section>
		<Section padding="120px 0 130px 0" background="linear-gradient(0deg,rgba(0,0,0,0) 0%,rgba(10, 26, 48, 0.96) 0%,rgba(39, 23, 23, 0.62) 0%),#070f4f url(https://uploads.quarkly.io/65cc7cb3fdcae00021de98ce/images/1-2.jpg?v=2024-02-14T09:52:47.712Z) 0% 0% /100% repeat scroll padding-box" lg-padding="80px 0 90px 0" quarkly-title="Stages/Steps-2">
			<Text margin="0px 0px 20px 0px" font="--headline2" color="--light" text-align="center">
				Features
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-template-rows="repeat(2, 1fr)"
				grid-gap="40px 30px"
				md-grid-template-columns="1fr"
				md-grid-template-rows="auto"
				md-grid-gap="26px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					padding="0px 70px 0px 0px"
					lg-padding="0px 0 0px 0px"
				>
					<Box
						min-width="40px"
						min-height="40px"
						background="#283c67"
						align-items="center"
						display="flex"
						justify-content="center"
						width="40px"
						height="40px"
						border-radius="50%"
					>
						<Text margin="0px 0px 0px 0px" color="--light" font="--lead" text-align="center">
							1
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 0px 25px">
						<Text margin="0px 0px 15px 0px" border-color="--color-light" color="--light" font="normal 500 20px/1.2 --fontFamily-sans">
							We Understand Every Home Has Its Own Character
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#bbc4cd">
							That’s why we offer personalized cleaning plans tailored to your preferences and lifestyle, ensuring your home receives the attention it deserves.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					padding="0px 70px 0px 0px"
					lg-padding="0px 0 0px 0px"
				>
					<Box
						min-width="40px"
						min-height="40px"
						background="#283c67"
						display="flex"
						align-items="center"
						justify-content="center"
						width="40px"
						height="40px"
						border-radius="50%"
					>
						<Text margin="0px 0px 0px 0px" color="--light" font="--lead" text-align="center">
							2
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 0px 25px">
						<Text margin="0px 0px 15px 0px" border-color="--color-light" color="--light" font="normal 500 20px/1.2 --fontFamily-sans">
							Eco-Friendly Cleaning
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#bbc4cd">
							We care about the well-being of your family and the planet. Using environmentally friendly cleaning products ensures a safe and sustainable clean for your home.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					padding="0px 70px 0px 0px"
					lg-padding="0px 0 0px 0px"
				>
					<Box
						min-width="40px"
						min-height="40px"
						background="#283c67"
						display="flex"
						align-items="center"
						justify-content="center"
						width="40px"
						height="40px"
						border-radius="50%"
					>
						<Text margin="0px 0px 0px 0px\n\n" color="--light" font="--lead" text-align="center">
							3
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 0px 25px">
						<Text margin="0px 0px 15px 0px" border-color="--color-light" color="--light" font="normal 500 20px/1.2 --fontFamily-sans">
							Reliable and Trustworthy Team
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#bbc4cd">
							Our cleaners are not just skilled—they are also vetted and trained to provide respectful and secure service. Your peace of mind is our top priority.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					padding="0px 70px 0px 0px"
					lg-padding="0px 0 0px 0px"
				>
					<Box
						min-width="40px"
						min-height="40px"
						background="#283c67"
						display="flex"
						align-items="center"
						justify-content="center"
						width="40px"
						height="40px"
						border-radius="50%"
					>
						<Text margin="0px 0px 0px 0px" color="--light" font="--lead" text-align="center">
							4
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 0px 25px">
						<Text margin="0px 0px 15px 0px" border-color="--color-light" color="--light" font="normal 500 20px/1.2 --fontFamily-sans">
							Satisfaction Guaranteed
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#bbc4cd">
							We strive for perfection in every clean. If you’re not satisfied, we promise to make it right. Your happiness is the measure of our success.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer>
			<Override slot="text4" />
			<Override slot="text3" />
			<Override slot="text1" />
			<Override slot="text" />
			<Override slot="text2" />
			<Override slot="link4" />
			<Override slot="link3" />
			<Override slot="text5" />
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65bb71eefdcae00021dd9faf"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});